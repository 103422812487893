import React, { Suspense } from "react";
import "./App.css";
import Popup from "reactjs-popup";
import SpotifyPlayer from "./Components/SpotifyPlayer";
import SpotifyLogo from "./Components/Spotify_logo.svg";
import LoadingGif from "./pages/loading.gif";

import Icon from "@mui/material/Icon";

const VippsKasse = React.lazy(() => import("./pages/VippsKasse"));
const Connect = React.lazy(() => import("./pages/Connect"));
const RentAComic = React.lazy(() => import("./pages/RentAComic"));
const NorwegianRails = React.lazy(() => import("./pages/NorwegianRails"));
const Amigo = React.lazy(() => import("./pages/Amigo"));
const Shifter = React.lazy(() => import("./pages/Shifter"));

function App() {
  return (
    <div className="content">
      <div className="grid">
        <div className="div1">
          <div className="portrait-container">
            <div className="portrait">
              <img src="images/erls_t.png" alt="Bilde av Erlend" />
            </div>
          </div>
          <div className="intro-container">
            <p className="introduction">
              Heisann! Jeg heter Erlend og er en interaksjonsdesigner bosatt i
              Oslo. Akkurat nå jobber jeg som designer hos{" "}
              <a href="https://bekk.no" target="_blank" rel="noreferrer">
                Bekk
              </a>{" "}
              med et oppdrag for{" "}
              <a href="https://kartverket.no" target="_blank" rel="noreferrer">
                Kartverket
              </a>
              .
            </p>
            <p className="introduction">
              Her finner du et utvalg tidligere prosjekter jeg har jobbet med.
              Ta gjerne kontakt om du lurer på noe 🤠
            </p>
            <a
              href="mailto:erlend@erlendstolan.no"
              className="introduction-email underline"
            >
              Send meg en e-post
            </a>
          </div>
        </div>
        <div className="div2">
          <div className="blob blob-vipps"></div>
          <Popup
            className="vipps-modal"
            id="vippsmodal"
            trigger={
              <button className="btn btn-vipps">
                Vipps Kasse <Icon className="material-ico">open_in_full</Icon>
              </button>
            }
            modal
            lockScroll
          >
            {(close) => (
              <div>
                <p onClick={close} className="modal-closebtn">
                  <Icon className="material-ico">close</Icon>
                </p>
                <div className="project-modal">
                  <Suspense
                    fallback={
                      <div className="loading-gif">
                        <img src={LoadingGif} alt="Laster inn" />
                      </div>
                    }
                  >
                    <VippsKasse />
                  </Suspense>
                </div>
              </div>
            )}
          </Popup>
        </div>
        <div className="div3">
          <a
            href="https://open.spotify.com/user/1111697906?si=f7e72ae1e9e94589"
            target="_blank"
            rel="noreferrer"
            className="spotify-logo"
          >
            <img src={SpotifyLogo} alt="Spotify Logo" />
          </a>
          <SpotifyPlayer
            client_id="a8ff1a70e75f44b09c015fcae43417a0"
            client_secret="92a0240d36904d69a3c00453de0f6e57"
            refresh_token="AQBdsT8JyXZS8GvFJQECBj6G4YNq4ktejWqLLjTLx-Hi9pNIROde0gP2I2IpPMRftO5K4pFmet7ddMaMDqExlLTHVN47F33vjSYmul0kRgoI2LQpYfHYLvjFTa5sJEpe9YY"
          />
        </div>
        <div className="div4">
          <img
            className="linkedin-img"
            src="images/linkedin.svg"
            alt="Logoen til LinkedIn"
          />
          <a
            href="https://www.linkedin.com/in/erlend-stølan-1078b916b/"
            target="_blank"
            rel="noreferrer"
            className="btn btn-linkedin hide-on-desktop"
          >
            LinkedIn <Icon className="material-ico">launch</Icon>
          </a>
          <a
            href="https://www.linkedin.com/in/erlend-stølan-1078b916b/"
            target="_blank"
            rel="noreferrer"
            className="btn btn-linkedin hide-on-mobile"
          >
            Legg meg til på LinkedIn{" "}
            <Icon className="material-ico">launch</Icon>
          </a>
        </div>
        <div className="div5">
          <div className="blob blob-connect"></div>
          <Popup
            className="connect-modal"
            id="connectmodal"
            trigger={
              <button className="btn btn-connect">
                Connect <Icon className="material-ico">open_in_full</Icon>
              </button>
            }
            modal
            lockScroll
          >
            {(close) => (
              <div>
                <p onClick={close} className="modal-closebtn">
                  <Icon className="material-ico">close</Icon>
                </p>
                <div className="project-modal">
                  <Suspense
                    fallback={
                      <div className="loading-gif">
                        <img src={LoadingGif} alt="Laster inn" />
                      </div>
                    }
                  >
                    <Connect />
                  </Suspense>
                </div>
              </div>
            )}
          </Popup>
        </div>
        <div className="div6">
          <div className="blob blob-rentacomic"></div>
          <Popup
            className="rentacomic-modal"
            id="rentacomicmodal"
            trigger={
              <button className="btn btn-rentacomic">
                Rent-A-Comic <Icon className="material-ico">open_in_full</Icon>
              </button>
            }
            modal
            lockScroll
          >
            {(close) => (
              <div>
                <p onClick={close} className="modal-closebtn">
                  <Icon className="material-ico">close</Icon>
                </p>
                <div className="project-modal">
                  <Suspense
                    fallback={
                      <div className="loading-gif">
                        <img src={LoadingGif} alt="Laster inn" />
                      </div>
                    }
                  >
                    <RentAComic />
                  </Suspense>
                </div>
              </div>
            )}
          </Popup>
        </div>
        <div className="div7">
          <div className="blob blob-norwegianrails"></div>
          <Popup
            className="norwegianrails-modal"
            id="norwegianrailsmodal"
            trigger={
              <button className="btn btn-norwegianrails">
                Norwegian Rails{" "}
                <Icon className="material-ico">open_in_full</Icon>
              </button>
            }
            modal
            lockScroll
          >
            {(close) => (
              <div>
                <p onClick={close} className="modal-closebtn">
                  <Icon className="material-ico">close</Icon>
                </p>
                <div className="project-modal">
                  <Suspense
                    fallback={
                      <div className="loading-gif">
                        <img src={LoadingGif} alt="Laster inn" />
                      </div>
                    }
                  >
                    <NorwegianRails />
                  </Suspense>
                </div>
              </div>
            )}
          </Popup>
        </div>
        <div className="div8">
          <div className="blob blob-amigo"></div>
          <Popup
            className="amigo-modal"
            id="amigomodal"
            trigger={
              <button className="btn btn-amigo">
                Amigo <Icon className="material-ico">open_in_full</Icon>
              </button>
            }
            modal
            lockScroll
          >
            {(close) => (
              <div>
                <p onClick={close} className="modal-closebtn">
                  <Icon className="material-ico">close</Icon>
                </p>
                <div className="project-modal">
                  <Suspense
                    fallback={
                      <div className="loading-gif">
                        <img src={LoadingGif} alt="Laster inn" />
                      </div>
                    }
                  >
                    <Amigo />
                  </Suspense>
                </div>
              </div>
            )}
          </Popup>
        </div>
        <div className="div9">
          <div className="blob blob-shifter"></div>
          <Popup
            className="shifter-modal"
            id="shiftermodal"
            trigger={
              <button className="btn btn-shifter">
                Shifter <Icon className="material-ico">open_in_full</Icon>
              </button>
            }
            modal
            lockScroll
          >
            {(close) => (
              <div>
                <p onClick={close} className="modal-closebtn">
                  <Icon className="material-ico">close</Icon>
                </p>
                <div className="project-modal">
                  <Suspense
                    fallback={
                      <div className="loading-gif">
                        <img src={LoadingGif} alt="Laster inn" />
                      </div>
                    }
                  >
                    <Shifter />
                  </Suspense>
                </div>
              </div>
            )}
          </Popup>
        </div>
      </div>
    </div>
  );
}

export default App;
