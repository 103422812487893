import { useEffect, useState } from "react";
import getRecentlyPlayingItem from "./SpotifyRecentlyApi";
import MusicLottie from "./musicanimation.gif";

function SpotifyRecentlyPlayer(props) {
  const [result, setResult] = useState({});

  useEffect(() => {
    Promise.all([
      getRecentlyPlayingItem(
        props.client_id,
        props.client_secret,
        props.refresh_token
      ),
    ]).then((results) => {
      setResult(results[0]);
    });
  }, [props.client_id, props.client_secret, props.refresh_token]);

  return (
    <div className="recentlyplayingcard">
      <div className="music-status">
        <img src={MusicLottie} className="music-gif" alt="" />
        <p className="music-status-text">Sist hørt på</p>
      </div>
      <p className="song-title">{result.title}</p>
      <p className="song-artist">{result.artist}</p>
    </div>
  );
}

export default SpotifyRecentlyPlayer;
