import { useEffect, useState } from "react";
import getNowPlayingItem from "./SpotifyApi";
import SpotifyRecentlyPlayer from "./SpotifyRecentlyPlayer";
import "../App.css";
import MusicLottie from "./musicanimation.gif";

function SpotifyPlayer(props) {
  const [result, setResult] = useState({});

  useEffect(() => {
    Promise.all([
      getNowPlayingItem(
        props.client_id,
        props.client_secret,
        props.refresh_token
      ),
    ]).then((results) => {
      setResult(results[0]);
    });
  }, [props.client_id, props.client_secret, props.refresh_token]);

  return result.isPlaying ? (
    <div className="nowplayingcard">
      <div className="music-status">
        <img src={MusicLottie} className="music-gif" alt="" />
        <p className="music-status-text">Aktiv nå</p>
      </div>
      <p className="song-title">{result.title}</p>
      <p className="song-artist">{result.artist}</p>
    </div>
  ) : (
    <SpotifyRecentlyPlayer
      client_id="a8ff1a70e75f44b09c015fcae43417a0"
      client_secret="92a0240d36904d69a3c00453de0f6e57"
      refresh_token="AQDNW8ly9pHcyPxvSuYoThEajP8PYVIlcCtqlwpwgSM78InrmUotG4hivJ1nvDZzgeLwy2GUMswfKckQ65wLp6HiHIcoA4hCMnkuKBEDmyE7BaUx02TQT0zSNjymkXc92ZY"
    />
  );
}

export default SpotifyPlayer;
